import React, { FC } from 'react';
import { AssetFile } from './interfaces/asset-file.interface';
import Media from 'react-media';
import { Lazy } from '../Lazy';
import { graphql, useStaticQuery } from 'gatsby';
import { joinPaths, pathJoin } from '../../../common/util/path';
import { forceCheck } from 'react-lazyload';

export type ContentfulAssetProps = {
  assetFile?: AssetFile;
  fallBackUrl?: string;
  handleOnLoad?: React.DOMAttributes<HTMLImageElement>['onLoad'];
} & React.ImgHTMLAttributes<HTMLImageElement>;

const getFileUrl = (baseURL: string, af?: AssetFile, isMobile?: boolean) => {
  if (!af) {
    return;
  }

  switch (af.activeAsset) {
    case 'Brandfolder Asset':
      if (isMobile && af?.brandfolderAssetMobile?.file?.publicURL) {
        return joinPaths(baseURL, af?.brandfolderAssetMobile?.file?.publicURL);
      } else if (af?.brandfolderAsset) {
        return joinPaths(baseURL, af.brandfolderAsset.file.publicURL);
      }
    case 'Contentful Asset':
      if (af?.contentfulAsset?.file?.publicURL) {
        return joinPaths(baseURL, af.contentfulAsset.file.publicURL);
      }
  }
};

export const ContentfulAssetFile: FC<ContentfulAssetProps> = ({
  fallBackUrl,
  assetFile,
  handleOnLoad,
  ...props
}) => {
  React.useEffect(() => {
    forceCheck(), [];
  });

  return (
    <ContentfulAssetFileURL
      handleOnLoad={handleOnLoad}
      fallBackUrl={fallBackUrl}
      assetFile={assetFile}
    >
      {({ url }) =>
        url ? (
          <Lazy lineHeight={0}>
            <img
              onLoad={handleOnLoad}
              alt={assetFile?.altText || props.alt || ''}
              {...props}
              src={url}
            />
          </Lazy>
        ) : null
      }
    </ContentfulAssetFileURL>
  );
};

export const ContentfulAssetFileURL: FC<ContentfulAssetProps & {
  children: (props: { url: string | null }) => React.ReactChild | null;
}> = ({ fallBackUrl, assetFile, children }) => {
  const baseURL =
    useStaticQuery(graphql`
      query {
        site {
          siteMetadata {
            baseURL
          }
        }
      }
    `)?.site?.siteMetadata?.baseURL || 'https://www.cloudflare.com';
  return (
    <Media query={{ maxWidth: 640 }}>
      {isMobile => {
        const url = getFileUrl(baseURL, assetFile, isMobile) || fallBackUrl || '';
        return children({ url });
      }}
    </Media>
  );
};
