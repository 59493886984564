import React, { FC } from 'react';
import Layout from '../layout/layout';
import { Helmet } from 'react-helmet';
import { OverviewTab } from './overview';
import { HeroHeader } from './common/HeroHeader';
import { GatsbyTemplateProps } from '../../common/interfaces/gatsby-template-props.interface';
import { AppContextProps } from '../../common/interfaces/locale-props.interface';
import { graphql } from 'gatsby';
import { BladeSubNav } from '../common/blades/BladeSubNav';
import {
  BlogDataInterface,
  FullContentInterface,
  ResourceGrid,
} from './interfaces/welcome-center.interface';
import { ContentfulBladeHero } from '../../contentful/content-types/blades/blade-hero-animation';
import { ContentfulBladeSubNav } from '../../contentful/content-types/blades/blade-sub-nav';
import { useCMSState } from '@internal/useCMSHook';
import { useConfiguration } from '../../common/hooks/useConfiguration';
import { MainFooter } from '../layout/refreshed-footer/footer.interfaces';
import { ContentfulNavNavigationGroup } from '../../contentful/content-types/nav-navigation-group';

export const query = graphql`
  query($locale: String!) {
    headerData: navNavigationGroup(
      contentfulId: { eq: "3hzSjT58plRWzuQrC4j4ny" }
      locale: { eq: $locale }
    ) {
      ...navNavigationGroupFragment
    }
    footerData: mainFooter(locale: { eq: $locale }) {
      ...mainFooterFragment
    }
    fullContent: bladeFullContent(
      contentfulId: { eq: "2ieCqXUDGcD2sUCoJbxUn4" }
      locale: { eq: $locale }
    ) {
      contentfulId
      copy
      title
    }
    heroSection: bladeHeroAnimation(
      contentfulId: { eq: "1N7V2AlkNnzQv0q9JBZi6w" }
      locale: { eq: $locale }
    ) {
      ...bladeHeroAnimationFragment
    }
    resourceGrid: bladeInfoBlocks(
      contentfulId: { eq: "5qZqpdha26WtvVPri2e2t7" }
      locale: { eq: $locale }
    ) {
      title
      contentfulId
      relatedInfoBlocks {
        title
        description
        linkText
        linkUrl
      }
    }
    subnav: bladeSubNav(contentfulId: { eq: "1e0eITYdkm5xNNEvoFpLzK" }, locale: { eq: $locale }) {
      ...subnavFragment
    }
    fromBlog: bladeBlogLinks(
      contentfulId: { eq: "fRI1pft8GCIZschPMPn0b" }
      locale: { eq: $locale }
    ) {
      title
      contentfulId
      firstBlurbTitle
      firstBlurbBody
      firstBlurbUrl
      firstBlurbLinkText

      secondBlurbTitle
      secondBlurbBody
      secondBlurbUrl
      secondBlurbLinkText

      thirdBlurbTitle
      thirdBlurbBody
      thirdBlurbUrl
      thirdBlurbLinkText
    }
  }
`;

export interface WelcomeCenterProps extends GatsbyTemplateProps<AppContextProps> {
  data: {
    headerData: ContentfulNavNavigationGroup;
    footerData: MainFooter;
    fullContent: FullContentInterface;
    heroSection: ContentfulBladeHero;
    subnav: ContentfulBladeSubNav;
    resourceGrid: ResourceGrid;
    fromBlog: BlogDataInterface;
  };
}

const Overview: FC<WelcomeCenterProps> = ({ data, pageContext }) => {
  const config = useConfiguration();
  let { footerData, headerData, fromBlog, heroSection, fullContent, resourceGrid, subnav } = data;
  if (config.targetEnv === 'staging') {
    footerData = useCMSState<MainFooter>(footerData);
    headerData = useCMSState<ContentfulNavNavigationGroup>(headerData);
    subnav = useCMSState<ContentfulBladeSubNav>(subnav);
    resourceGrid = useCMSState<ResourceGrid>(resourceGrid);
    fromBlog = useCMSState<BlogDataInterface>(fromBlog);
    heroSection = useCMSState<ContentfulBladeHero>(heroSection);
    fullContent = useCMSState<FullContentInterface>(fullContent);
  }
  return (
    <Layout headerData={headerData} pageContext={pageContext} footerData={footerData}>
      <Helmet>
        <title>Welcome Center | Cloudflare</title>
      </Helmet>

      <HeroHeader title={fullContent.title} subtitle={fullContent.copy}></HeroHeader>

      <BladeSubNav blade={subnav} pagePrefix={'/'} />
      <OverviewTab
        heroSection={heroSection}
        resourceGrid={{
          title: resourceGrid.title,
          resources: resourceGrid.relatedInfoBlocks,
        }}
        fromBlog={fromBlog}
      ></OverviewTab>
    </Layout>
  );
};

export default Overview;
